import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef, useState } from 'react'

import { Carousel } from '@/features/carousel'
import { mq, widthInCols } from '@/theme/mixins'

import MediaCarouselImage from './MediaCarouselImage'
import MediaCarouselVideo from './MediaCarouselVideo'

export type MediaCarouselLayout = 'ARTICLE' | 'HIGHLIGHT'

interface Props extends ComponentPropsWithoutRef<'div'> {
  data?: Queries.MediaCarouselFragment | null
  layout: MediaCarouselLayout
}

const MediaCarousel = ({
  data,
  layout,
  ...props
}: Props): JSX.Element => {
  const [height, setHeight] = useState<number | null>(null)
  const styles = {
    slider: css`
      ${layout === 'ARTICLE' &&
      css`
        grid-column: 1 / -1;
        --border-thickness: 0px;
        --gtr: calc(2 * var(--border-thickness) + var(--gtr-12));
        --slide-width: ${widthInCols({ count: 10 })};
        --scroll-width: calc(var(--slide-width) + var(--gtr));
        margin: 1em 0 2.5em;
        height: calc(${height}px + 2 * var(--border-thickness));
        transition: height 750ms ease;
        ${mq().ml} {
          --slide-width: ${widthInCols({ count: 11 })};
        }
        ${mq().s} {
          --slide-width: calc(var(--grid-w) - 3 * var(--margin));
        }
      `}
      ${layout === 'HIGHLIGHT' &&
      css`
        --gtr: 0.5rem;
        --slide-width: calc(
          (100vw - 2 * var(--margin) - var(--gtr-36)) * 7 / 12
        );
        --scroll-width: var(--slide-width);
        height: 100%;
        ${mq().ms} {
          --slide-width: calc(100vw - 2 * var(--margin));
        }
        ${mq().s} {
          --slide-width: calc(100vw - 2 * var(--gtr-12));
        }
      `}
    `,
    sliderContent: css`
      display: grid;
      grid-column-gap: var(--gtr);
      grid-template-columns: repeat(
        ${data?.media?.length},
        var(--slide-width, auto)
      );
      ${layout === 'ARTICLE' &&
      css`
        padding-left: var(--margin);
        padding-right: calc(
          var(--margin) + ${widthInCols({ count: 2 })} + var(--gtr)
        );
        ${mq().ml} {
          padding-right: calc(
            var(--margin) + ${widthInCols({ count: 1 })} + var(--gtr)
          );
        }
        ${mq().s} {
          padding: 0 calc(1.5 * var(--margin));
        }
      `}
      ${layout === 'HIGHLIGHT' &&
      css`
        padding: 0;
        > * {
          height: 100%;
        }
      `}
    `,
    scrollArea: css`
      ${layout === 'ARTICLE' &&
      css`
        ${mq().s} {
          scroll-padding: 0 calc(1.5 * var(--margin));
        }
      `}
      ${layout === 'HIGHLIGHT' &&
      css`
        scroll-padding: 0;
        height: 100%;
      `}
    `,
    nav: css`
      ${layout === 'ARTICLE' &&
      css`
        width: calc(var(--slide-width) + 2 * var(--margin));
        ${mq().s} {
          width: 100%;
        }
      `}
    `,
    videoContainer: css`
      background: rgba(0, 0, 0, 0.9);
      display: grid;
      position: relative;
      align-self: flex-start;
    `,
  }
  return (
    <Carousel
      css={styles.slider}
      contentCss={styles.sliderContent}
      navCss={styles.nav}
      scrollAreaCss={styles.scrollArea}
      snap
      navVariant={layout === 'ARTICLE' ? 'OVERLAY' : 'CORNER'}
      {...props}
    >
      {data?.media?.map(block => {
        switch (block?.__typename) {
          case 'DatoCmsImageBlock':
            return (
              <MediaCarouselImage
                data={block}
                carouselHeight={height}
                setCarouselHeight={
                  layout !== 'HIGHLIGHT' ? setHeight : undefined
                }
                layout={layout}
                key={block.id}
              />
            )
          case 'DatoCmsExternalVideoBlock':
            return (
              <div
                css={styles.videoContainer}
                key={block.id}
              >
                <MediaCarouselVideo
                  data={block}
                  layout={layout}
                  carouselHeight={height}
                  setCarouselHeight={
                    layout !== 'HIGHLIGHT' ? setHeight : undefined
                  }
                />
              </div>
            )
        }
      })}
    </Carousel>
  )
}

export const MediaCarouselFragment = graphql`
  fragment MediaCarousel on DatoCmsMediaCarousel {
    __typename
    id: originalId
    media {
      ... on DatoCmsImageBlock {
        ...MediaCarouselImage
      }
      ... on DatoCmsExternalVideoBlock {
        ...ExternalVideoBlock
      }
    }
  }
`

export default MediaCarousel
